// Step 1: Import React
import * as React from 'react'
// import Layout from '../components/layout'
// import {StaticImage} from 'gatsby-plugin-image'
import { Link } from 'gatsby'



// Step 2: Define your component
const HomeCtac = () => {
  return (
    
    <section className="ctac">
        <div className="ctac__heading-group">

          <div className="heading-md__ctac">Get In Touch</div>
          <div className="heading-lg__ctac">If You Are Interested,</div>
          <div className="heading-lg__ctac">Feel Free To Leave A Message.</div>
        </div>
        <div className="btn-group btn-group__ctac">
            <Link to='/contact' className="btn btn-pink">Leave me a message</Link>
            <span className='white-text med lg-text'>Or</span>
            <Link to='/portfolio' state={{category: "default"}} className="btn">Check out my portfolio</Link>
        </div>
    </section>
   

   
  )
}
// Step 3: Export your component
export default HomeCtac