// Step 1: Import React
import * as React from 'react'
// import Layout from '../components/layout'
// import {StaticImage} from 'gatsby-plugin-image'
// import { Link } from 'gatsby'

import Ico1 from '../../images/ico1.png'
import Ico2 from '../../images/ico2.png'
import Ico3 from '../../images/ico3.png'
import Illustration1 from '../../images/headway-5QgIuuBxKwM-unsplash.jpg'
import Illustration2 from '../../images/brooke-cagle-g1Kr4Ozfoac-unsplash.jpg'
import Illustration3 from '../../images/avel-chuklanov-DUmFLtMeAbQ-unsplash.jpg'

// Step 2: Define your component
const servicesPage = () => {
  return (
    
    <section className="services">
        <div className="services__heading">
          <p className="services__heading-sm">Services</p>
          How I help Bring your Content to Life
        </div>

        <div className="services__content">

          <div className="services__text">

            <div className="services__list">

              <div className="services__desc">
                <div className="services__icon-container">
                  <img src={Ico1} alt="heart icon" className="services__icon" />
                </div>

                <div className="services__desc-text">
                  <div className="services__desc-text--heading">Learning Strategy</div>
                  <div className="services__desc-text--par">
                    A smart approach is the foundation of a successful learning experience. I'll approach your project with a well-though-out design process that will determine the most effective way to meet your objectives
                  </div>
                </div>

              </div>
              <div className="services__desc">
                <div className="services__icon-container">
                  <img src={Ico2} alt="heart icon" className="services__icon" />
                </div>

                <div className="services__desc-text">
                  <div className="services__desc-text--heading">eLearning Design</div>
                  <div className="services__desc-text--par">
                    With the optimal strategies, the designer will begin conceptualizing the learning experience's tone, look and feel. The custom solution presented in the detailed blueprint will be provided to your learner
                  </div>
                </div>

              </div>
              <div className="services__desc">
                <div className="services__icon-container">
                  <img src={Ico3} alt="heart icon" className="services__icon" />
                </div>

                <div className="services__desc-text">
                  <div className="services__desc-text--heading">Development</div>
                  <div className="services__desc-text--par">
                    All the fun will begin here, where the solution will be generated in the agreed format.  
                  </div>
                </div>

              </div>
              
            </div>


          </div>

          <div className="services__composition">

              <div className="services__composition-container">
                <img alt="comp 1" className="services__composition-photo services__composition-photo--p1" src={Illustration1}/>
                <img alt="comp 2" className="services__composition-photo services__composition-photo--p2" src={Illustration2}/>
                <img alt="comp 3" className="services__composition-photo services__composition-photo--p3" src={Illustration3}/>


              </div>
              {/* <img srcset="img/nat-1.jpg 300w, img/nat-1-large.jpg 1000w"
                    sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                    alt="Photo 1"
                    className="composition__photo composition__photo--p1"
                    src={Illustration}/>

              <img srcset="img/nat-2.jpg 300w, img/nat-2-large.jpg 1000w"
                    sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                    alt="Photo 2"
                    className="composition__photo composition__photo--p2"
                    src={Illustration}/> */}


              
          </div>
          
        </div>
    </section>
   

   
  )
}
// Step 3: Export your component
export default servicesPage