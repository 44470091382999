// Step 1: Import React
import * as React from 'react'
// import Layout from '../components/layout'
import {StaticImage} from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import Fb from '../../assets/facebook.svg'
import Gm from '../../assets/google-plus.svg'
import Li from '../../assets/linkedin2.svg'


// Step 2: Define your component
const HomeIntro = () => {
  // console.log(data)
  return (
    
    <section className="home-intro">
        <div className="home-intro__coverimg">
          <StaticImage alt={`cover img`} className="coverimg" src='../../images/marissa-grootes-AVWmtS-qK3Y-unsplash.jpg'/>
          <div className="home-intro__bgoverlay"></div>
        </div>
        <div className="home-intro__text">
          <div className="home-intro__text--main">
            <p className="paragraph-sm">HI THERE!</p>
            <p className="heading-lg">Designing quality <span className="pink-text">learning experience</span> is my passion</p>
            
          </div>
          <hr className="lbr" />

          <div className="home-intro__text--sub">
            <p className="paragraph-sm">I am an e-learning designer that specializes in many fields such as interactive courses, video learning, virtual training, microlearning, and e-learning games. In addition, I have the capability to manage training and education systems, and also experience in educational live shows: live stream, talk shows, game shows, conferences.</p>
          </div>
          <div className="btn-group">
              <Link to='/portfolio' state={{category: "default"}} className="btn btn-pink">See My Works</Link>
              <Link to='/about' className="btn">About Me</Link>
          </div>

          <div className="media">
              <a aria-label="facebook" href='https://www.facebook.com/profile.php?id=100011540748903' className='media__item'><Fb className='icon'/></a>
              <a aria-label="LinkedIn" href='https://www.linkedin.com/in/khanh-linh-le-91b48719a/' className='media__item'><Li className='icon'/></a>
              <a aria-label="Gmail" href='mailto:elearninglilian@gmail.com' className='media__item'><Gm className='icon'/></a>
          </div>
        </div>
    </section>
   

   
  )
}
// Step 3: Export your component
export default HomeIntro