// Step 1: Import React
import * as React from 'react'
import Layout from '../components/layout'
// import {StaticImage} from 'gatsby-plugin-image'
// import { Link } from 'gatsby'

// import { graphql } from 'gatsby'

import Intro from '../components/home/home-intro'
import Ctac from '../components/home/ctac'
import Services from '../components/home/services'
import RecentWorks from '../components/home/recent-works'




// Step 2: Define your component
const IndexPage = ({data}) => {
  // console.log(data)
  return (
    <Layout pageTitle="Home Page">
      <Intro />
      <RecentWorks />
      <Services />
      <Ctac />
      
      
    </Layout>
   
  )
}

// export const recentWorksQuery = graphql`
//   query {
//     allWpPost(sort: {fields: date, order: DESC}) {
//       nodes {
//         title
//         date(formatString: "MMMM DD, YYYY")
//         slug
//         excerpt
//         featuredImage {
//           node {
//             sourceUrl
//           }
//         }
//       }
//     }
//   }
// `
// Step 3: Export your component
export default IndexPage
