// Step 1: Import React
import * as React from 'react'
// import Layout from '../components/layout'
import { graphql, useStaticQuery } from 'gatsby'
import {GatsbyImage} from 'gatsby-plugin-image'
import { Link } from 'gatsby'

// import Fb from '../../assets/facebook.svg'
// import Gm from '../../assets/google-plus.svg'
// import Li from '../../assets/linkedin2.svg'
// import Illustration from '../../images/brooke-cagle-g1Kr4Ozfoac-unsplash.jpg'


// Step 2: Define your component
const RecentWorks = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpCourse(sort: {fields: date, order: DESC} limit: 6) {
        nodes {
          id
          title
          date(formatString: "MMMM DD, YYYY")
          slug
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
              altText
            }
          }
        }
      }
    }
  `)
  return (
    
    <section className="recents">
        <h4 className="heading-md recents__text-heading--sm ">My Recent Works</h4>
        <div className="recents__container">
          {
            data.allWpCourse.nodes.map(node => {
              
              return (
                <div key={node.id} className="recents__course">
                  <Link to={`/portfolio/${node.slug}`}>

                    <div className='recents__course-thumbnail'>
                      <GatsbyImage image={node.featuredImage.node.localFile.childImageSharp.gatsbyImageData} alt={node.featuredImage.node.altText}/>
                      <div className="recents__course-bgoverlay"></div>
                    </div>
                      <div className="recents__course-heading">
                        <h1 className="recents__course-title">{node.title}</h1>
                        <div className="recents__course-date">{node.date}</div>
                        {/* <Link to={`/portfolio/${node.slug}`} className="btn">Link to course</Link> */}

                      </div>
                  </Link>
                    
                </div>)
            })
          }
        </div>
        <Link to="/portfolio" state={{category: "default"}} className='btn btn-pink recents__btn'>See More</Link>
    </section>
   

   
  )
}

// Step 3: Export your component
export default RecentWorks